import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    let locale = this.route.snapshot.paramMap.get('locale');
    if (
      locale !== 'en' &&
      locale !== 'it' &&
      locale !== 'es' &&
      locale !== 'de' &&
      locale !== 'fr' &&
      locale !== 'he' &&
      locale !== 'nl'
    ) {
      locale = 'en';
    }
    this.translate.use(locale);
  }
}
