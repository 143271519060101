import { HttpsenderService } from './httpsender.service';
import { ConnectInstagramService } from 'src/app/shared/connect-instagram.service';
import { AuthService } from './auth.service';
import { Injectable, ɵɵresolveBody } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  token;
  userData;
  pk;

  constructor(
    private authService: AuthService,
    private connectIGService: ConnectInstagramService,
    private sender: HttpsenderService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let locale = next.paramMap.get('locale');
    console.log(locale);
    if (
      locale !== 'en' &&
      locale !== 'it' &&
      locale !== 'es' &&
      locale !== 'de' &&
      locale !== 'fr' &&
      locale !== 'he' &&
      locale !== 'nl'
    ) {
      locale = 'en';
    }
    if (next.fragment) {
      try {
        console.log(locale);
        this.generateData(next.fragment);

        if (
          !next.paramMap.get('locale') &&
          this.userData.data.client == 'viky'
        ) {
          locale = 'it';
        }

        this.router.navigateByUrl('/' + locale + '/' + this.pk + '/activity');

        return true;
      } catch (error) {
        this.router.navigateByUrl('/' + locale + '/404');

        return true;
      }
    } else {
      this.router.navigateByUrl('/' + locale + '/404');
      this.authService.noClient = true;

      return true;
    }
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return false;
  }

  generateData(token) {
    this.token = token;
    this.userData = this.authService.jwt.decode(token);
    this.pk = this.userData.data.pk;

    this.sender.setData(this.token);
    this.connectIGService.setData(this.pk);
    this.authService.setData(this.token, this.userData);

    localStorage.setItem(this.pk, this.token);
    const data = {
      pk: this.userData.data.pk,
      username: this.userData.data.username,
      client: this.userData.data.client,
    };
    localStorage.setItem('userData:' + this.pk, JSON.stringify(data));
  }
}
