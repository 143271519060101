import { HttpsenderService } from './httpsender.service';
import { ConnectInstagramService } from 'src/app/shared/connect-instagram.service';
import { AuthService } from './auth.service';
import { Injectable, ɵɵresolveBody } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard implements CanActivate {
  token;
  userData;
  pk;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (next.queryParams['redirect']) {
      localStorage.setItem('redirect', next.queryParams['redirect']);
    }

    return true;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return false;
  }
}
