import { AuthService } from './auth.service';
import { ConnectInstagramService } from 'src/app/shared/connect-instagram.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpsenderService {
  // DATA
  token = null;

  constructor(private http: HttpClient) {}

  setData(token) {
    this.token = token;
  }

  // FUNCTIONS
  public get(path: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'JWT ' + this.token,
    });

    return this.http
      .get(environment.server + path, {
        observe: 'response',
        headers,
      })
      .pipe(
        catchError((error) => {
          if (error.status === 401) {
            // this.auth.logout();
          }
          return throwError(error);
        })
      );
  }

  public put(path: string, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'JWT ' + this.token,
    });

    return this.http
      .put(environment.server + path, body, {
        observe: 'response',
        headers,
      })
      .pipe(
        catchError((error) => {
          if (error.status === 401) {
            // this.auth.logout();
          }
          return throwError(error);
        })
      );
  }

  public delete(path: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'JWT ' + this.token,
    });

    return this.http
      .delete(environment.server + path, {
        observe: 'response',
        headers,
      })
      .pipe(
        catchError((error) => {
          if (error.status === 401) {
            // this.auth.logout();
          }
          return throwError(error);
        })
      );
  }

  public post(path: string, body: any) {
    let headers;
    const xFake = localStorage.getItem('x-fake');
    if (xFake == null) {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'JWT ' + this.token,
      });
    } else {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'JWT ' + this.token,
        'x-fake': xFake,
      });
    }

    return this.http
      .post(environment.server + path, body, {
        observe: 'response',
        headers,
      })
      .pipe(
        catchError((error) => {
          if (error.status === 401) {
            // this.auth.logout();
          }
          return throwError(error);
        })
      );
  }

  public patch(path: string, body: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'JWT ' + this.token,
    });

    return this.http
      .patch(environment.server + path, body, {
        observe: 'response',
        headers,
      })
      .pipe(
        catchError((error) => {
          if (error.status === 401) {
            // this.auth.logout();
          }
          return throwError(error);
        })
      );
  }
}
