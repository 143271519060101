import { Injectable } from '@angular/core';
import { HttpsenderService } from './httpsender.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectInstagramService {
  public twoFactorIdentifier: string;

  public challengeNonce: string;
  public challengeEmail: string;
  public challengePhone: string;
  public challengeMethod: string;

  public pk: string;

  constructor(public sender: HttpsenderService) {}

  setData(pk) {
    this.pk = pk;
  }

  addUsername(username: string, account) {
    const pk = this.pk;
    return this.sender.post('accounts', { pk, username, account });
  }

  loginWithPassword(password: string) {
    return this.sender.post('accounts/' + this.pk + '/login', {
      password,
    });
  }

  loginWithoutPassword() {
    return this.sender.post('accounts/' + this.pk + '/login', {});
  }

  getInstagramData() {
    return this.sender.get('accounts/' + this.pk);
  }

  // Challenge methods
  chooseChallengeVerificationMethod(method: string) {
    return this.sender.post(
      'accounts/' +
        this.pk +
        '/login/challenge/' +
        this.challengeNonce +
        '/choose-verify-method',
      { method }
    );
  }

  resendChallengeVerificationCode() {
    const method = this.challengeMethod === 'sms' ? '0' : '1';
    return this.sender.post(
      'accounts/' +
        this.pk +
        '/login/challenge/' +
        this.challengeNonce +
        '/resend',
      { method }
    );
  }

  sendChallengeVerificationCode(code: string) {
    return this.sender.post(
      'accounts/' +
        this.pk +
        '/login/challenge/' +
        this.challengeNonce +
        '/verify-code',
      { code }
    );
  }

  // Two factor
  sendTwofactorVerificationCode(code: string, method: number) {
    return this.sender.post(
      'accounts/' +
        this.pk +
        '/login/two-factor/' +
        this.twoFactorIdentifier +
        '/verify-code',
      { code, method }
    );
  }

  resendTwoFactorVerificationCode() {
    return this.sender.post(
      'accounts/' +
        this.pk +
        '/login/two-factor/' +
        this.twoFactorIdentifier +
        '/resend',
      {}
    );
  }

  // Send Two Factor Generic
  sendTwoFactorGenericCode(code: string) {
    return this.sender.post(
      'accounts/' +
        this.pk +
        '/login/two-factor/' +
        this.twoFactorIdentifier +
        '/verify-code',
      { code, is_generic: 'true' }
    );
  }

}
