import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // let locale = this.route.snapshot.paramMap.get('locale');
    // if (
    //   locale !== 'en' &&
    //   locale !== 'it' &&
    //   locale !== 'es' &&
    //   locale !== 'de' &&
    //   locale !== 'en'
    // ) {
    //   locale = 'en';
    // }
    // this.translate.use(locale);
  }
}
