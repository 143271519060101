import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './onboarding/landing-page/landing-page.component';
import { AuthGuard } from './shared/auth.guard';
import { PageNotFoundComponent } from './onboarding/page-not-found/page-not-found.component';
import { RedirectGuard } from './shared/redirect.guard';

const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
    canActivate: [RedirectGuard, AuthGuard],
  },
  {
    path: ':locale',
    component: LandingPageComponent,
    canActivate: [RedirectGuard, AuthGuard],
  },
  {
    path: ':locale/404',
    component: PageNotFoundComponent,
    canActivate: [RedirectGuard],
  },
  {
    path: ':locale/:pk',
    canActivate: [RedirectGuard],
    loadChildren: () =>
      import('./onboarding/onboarding.module').then(
        (module) => module.OnboardingModule
      ),
  },
  {
    path: '**',
    redirectTo: 'en/404',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
